import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`When designing for the generation of API keys, ensure users know what the key is created for, the security implications, and the end destination of the key.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "/experimental/overview"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainer"
    }}>{`Maintainer:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACJ0lEQVQoz1WR/08ScRzG+Wta/VRLSQTbqj8hCP6HfmirX5luIcH4zh1wfBMOZNlPTclGOkqljS1I86QJuFgtfxBhnXg0M4p8+nw+CNPbnj27u+den/f7OVXnSMb2zjZRhWgH0rnvVquo1euo1uqoXRR5tlutsdxA5JtKBZtbn3DYakGVXVrEjZvXcefuPUxoNBgbV2NcfQuTWi10U7eh1U0R6Qau1ZHnA42p1aPshGYSV65eg5jOQJXOPMd9/QM4nC5MzzzFrPUZLBYLbDYb3ubzKJU+oFwuEy8xlyQJyWSSZex2O2aJW61WmM1mFItFqOhLo9GIVCqNUEhAJBKFIISRSCTQ+PoNB80Wms1DyPIRFEUBvfLkIJ7nEYvFSD6CaDQKn8/HDmRAk8nEAC6nE16vFx6PB4FAAHuNfbR+dNFqy+gcK6SjNpTuT2SzWTYdhbjd7pEzIAUZDIYB0OViLymQ5wNoN/fRP+0A/RPg36+B4y+Wl18xoN/vZwNQvwTU6/Vk5RRbIxgMguN4iHNhxFbbeDjfx5OFHh4v/MGjTA8zi2d48TIHzu8lFYVYXhAEBqY9j4CiKDJgOBxGiATm4lFI9QNsNU6w+aXL9HFPwefvp1jKvoaHTERrGQ7hJHWNgHTlIZAWTIuOx+PoHstkxT5w1jvXb/ZT3uRycDgcLE/XpU7vL01IV6bTDYFUKyurKBTeY32jgA2id2vrRGssS1fkOI7pIvA/dxFGzcgP0X0AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an API key being generated",
        "title": "Example of an API key being generated",
        "src": "/static/f9e0d7d63d28b2e767049bc8423cc305/fb070/1.png",
        "srcSet": ["/static/f9e0d7d63d28b2e767049bc8423cc305/d6747/1.png 288w", "/static/f9e0d7d63d28b2e767049bc8423cc305/09548/1.png 576w", "/static/f9e0d7d63d28b2e767049bc8423cc305/fb070/1.png 1152w", "/static/f9e0d7d63d28b2e767049bc8423cc305/fb104/1.png 1728w", "/static/f9e0d7d63d28b2e767049bc8423cc305/8fefe/1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "general-access-keys"
    }}>{`General access keys`}</h2>
    <h3 {...{
      "id": "instant-generation"
    }}>{`Instant generation`}</h3>
    <p>{`Users click a primary `}<strong parentName="p">{`Generate`}</strong>{` button. Consider displaying a `}<strong parentName="p">{`Generating…`}</strong>{` state if the API key takes time to generate.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.190217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAA1UlEQVQoz2P4jwX8+/efIMClhgFTIUTl60///l989O//1SdA/BSCrwHx5cf//r/+/A9FLV4Df/8BK/3fv/v/f+GC//+lS///58v5/58/9/9/wfz//xlT/4PlQGp+/yXChX//Qmw9c/v7/wkb3v2ftuXd/ymb3v+fsvn9/6lA3Lf+3f+zd75D1BLnQoi1kxbf/C9hu/6/mueW/2I26/+LWq0D0/wW6/7PWHEbrObPn3/Eh+Gz19//n77y7v+56+/BNBhfff//5KV3/5+//k58GOJSSKwaAAisCzmLbk/5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a generate button.",
            "title": "Example of a generate button.",
            "src": "/static/4318b1a8e2e8c581fe61e8cb9f25b8a6/fb070/2.png",
            "srcSet": ["/static/4318b1a8e2e8c581fe61e8cb9f25b8a6/d6747/2.png 288w", "/static/4318b1a8e2e8c581fe61e8cb9f25b8a6/09548/2.png 576w", "/static/4318b1a8e2e8c581fe61e8cb9f25b8a6/fb070/2.png 1152w", "/static/4318b1a8e2e8c581fe61e8cb9f25b8a6/c3e47/2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`Once the API key is generated it displays in a modal. Include a `}<strong parentName="p">{`Copy`}</strong>{` button.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABfUlEQVQ4y62Sz07CQBDGOXrRi0cvxggknkw8gPEqVTjo2RM3E7n4CMT45wV8uVbBWiJtKZRSSgul3f3cXagWiAcDk/zyzU660y8zm8GGI0MpFQnXdUh6ZJJk3Uh6LDicTCYIgkAoZzweC5I8qacJw3DRYdqyrhtoNpvQNA2qqoqca6PRgGEY8DwPrusuwGsrDXkQQpkLH7Zto9/vw3Ecob848P0AMfsuigmiKGYaM5chu0uWHYoj2vYUptVDp2PCsiyYpingud3twLEtDAc2gpGLOJogno4RhQHocsPZgUBt6ZAVBbIs/6BwlDe8a12ououPtiO0ZXr4NIb4skbC9UpDPg+pdI58Po9sNjsnh9zhPg6OTrFXM7F7D2zfEezUKINg65bguE7Q8+ZjSzf0fR/VahWSJKFSqaBcLjOYXpZwcXWDs3oPxReg8EhReAKKz8DJA3D9Cgz82ci40X++Q/onyR4W3iHfVsw2twKvs9+z5aZ0Bt9H4ocmDjcZ32GayLxbHAXHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a successfully created API key",
            "title": "Example of a successfully created API key",
            "src": "/static/570370ddb418554f83c1688dc1f6a259/fb070/3.png",
            "srcSet": ["/static/570370ddb418554f83c1688dc1f6a259/d6747/3.png 288w", "/static/570370ddb418554f83c1688dc1f6a259/09548/3.png 576w", "/static/570370ddb418554f83c1688dc1f6a259/fb070/3.png 1152w", "/static/570370ddb418554f83c1688dc1f6a259/c3e47/3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "optionally"
    }}>{`Optionally:`}</h4>
    <ul>
      <li parentName="ul">{`Display two parts to the API key where required`}</li>
      <li parentName="ul">{`Provide information text about the API key`}</li>
      <li parentName="ul">{`Allow users to toggle the visibility of the key`}</li>
      <li parentName="ul">{`Provide a secondary link to download the key`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABeklEQVQ4y62Su04CQRSGKa2MnZWJJgKJlYmJqLWsQqG1FZ2FVL6AxqhP4IPZGAFZs6ACupe4F4bdxZ35nZllDUugMHCSL/+Zy579M+dksODIMMZkInQekhqZJJk3khoph0EQwPd9qZP5LMIwTDsct9zt9qCqKtqtFjRNk7nQZrPJz7pwXRe27cBxRvC153mglKYLihCbgT+QF1zXkx/HuYt+n8hzxmJoFCGKKAgZyLMpDuUSHWsIw/yGaRocE4auQzcMWJbJsfCpxwiX7CfAMCAIgykF4wWF9tZBvd5ArVbjWv/TRuMFT68GHlWHY+O55eD9y0O75+HD6COiUwqKN1GKh8jn88hmszG5HHKb69jY2sda9ROrl8BKlXIYli8ols4ptq8pTG/0bOMFCSGoVCpQFAXlchmlUonD9biIo5MzHFyZKNwDu7cMhTtgj+c7N8DpA2CT+MmE0X/OIZtJ0ofUHIpORrKDE4h9/nve2DGNEROT+GGJw0XGLzM1yQH8iUz6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a hidden API key",
            "title": "Example of a hidden API key",
            "src": "/static/4642ca3feb5fc62bac47218212ffa0e4/fb070/4.png",
            "srcSet": ["/static/4642ca3feb5fc62bac47218212ffa0e4/d6747/4.png 288w", "/static/4642ca3feb5fc62bac47218212ffa0e4/09548/4.png 576w", "/static/4642ca3feb5fc62bac47218212ffa0e4/fb070/4.png 1152w", "/static/4642ca3feb5fc62bac47218212ffa0e4/c3e47/4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a hidden API key</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABg0lEQVQ4y62SO0/CUBiGGZ2Mm5OJJgKJk4mJqLNUYdDZic1BJv+Axqi/wB/GpAFCrfVCL7TQC72AnPN6TstJqMHBwJc8+S5J3775vpPDkiNHKU0KnhdBaOREsWgIjYzDOI4RhmGSOVEUJYhazGcZjUZZh7OWu10NsixDVVUoipLUPHc6HWiaBt/34TguXDfF87xkRgjJCvIghDIXAWzbRr/fx2AwSLKA91EUso8IJpMJg2AYhPCHwTyHSYsvewzdtGAYOkzThK7rzJmOHqsNw8CXxmamnbik3zHGcYBRNEcwbQiU9y6arRaazWaGdruN51cLDdlDo+Pg5c3Fh+FD1X189oaYkDmCfCdS+RjFYhH5fH5KAYXtTWztHGKjrmP9GlirEwbF6hXByiXB7i2B5U/XNisYBAFqtRokSUK1WkWlUmGwfFrGydkFjm4slB6B/XuK0gNwwOq9O+D8CXCCdGXc6D/fIf0TcYfMO+TnTy/4Cz5nv2eHnckp/MUIP1Q4XGb8AGUTyDtciO1GAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a revealed API key",
            "title": "Example of a revealed API key",
            "src": "/static/48e6e0cf774891b938ade9be5469be49/fb070/5.png",
            "srcSet": ["/static/48e6e0cf774891b938ade9be5469be49/d6747/5.png 288w", "/static/48e6e0cf774891b938ade9be5469be49/09548/5.png 576w", "/static/48e6e0cf774891b938ade9be5469be49/fb070/5.png 1152w", "/static/48e6e0cf774891b938ade9be5469be49/c3e47/5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a revealed API key</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "name-the-api-key"
    }}>{`Name the API key`}</h3>
    <p>{`You can ask a user to provide a descriptive name before the key is generated. This is particularly useful in instances where a user may have keys for several applications stored in the same location.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABwklEQVQ4y5VTy07CQBRtSEz8An+A/1Hjwl/wk4waE2mJQVAWhqW68bFySQIuDM/SB5S20E5LQ+c4d0ixvuMkp+dO23vmzNy5CsTgnCMbFGf4z8j+V7IgL5THcrn8FWmaIq+h5N1MJhMMBgMYhiHZNE0wxhCG4Zrz8Xw+h+d5PwsOhzqazSba7TZarRbaLy/odDro9XqSu90u+v2+jIlpYRL9IihC0NNzHZjGSMCAMRrBEg5Hggm6rksmEYoty4Lv+wiC4DuHcoqOGeF1MIU5CWC5ESwnwHg8huu6MpHgOI58R85ms9n3DjPBnuGjPzTEWTqYTj0h5EkndLaUTI5s25ZHQIv8KJhNZr4L2zJlMWwhFIaBLAAhCCMELEYYxWDRQsbynQBVei34+R7FcYwkSeSV+DgS+voVPBK5aSby7nCxWKBer0PTNJTLZcmqqkItnaFUruL0ZoqTuxhHtwzHhDuGwxuG88cIUZLbcmaX7lOxWEShUICiKB+xsQVlR4eyLzphVyTtYYVtYPMAsPxVDVKe6xTaZqPRQKVSQa1We0f1AtWra2j3DOoToD1xwVzy2QPH5TNHnHf4uZf/6NhfennFb4O0wBFRRphvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an API key with a custom name",
            "title": "Example of an API key with a custom name",
            "src": "/static/3b78ee40c05593883f7d31ee16152a75/fb070/6.png",
            "srcSet": ["/static/3b78ee40c05593883f7d31ee16152a75/d6747/6.png 288w", "/static/3b78ee40c05593883f7d31ee16152a75/09548/6.png 576w", "/static/3b78ee40c05593883f7d31ee16152a75/fb070/6.png 1152w", "/static/3b78ee40c05593883f7d31ee16152a75/c3e47/6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.03260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1klEQVQ4y5VTTW/TQBD1BYlfwD/I3wGpBy6IM38JQSsaO0JKAjmgHAuqBPTEMSiphHCcxGu7/o7Xa8eJ/Rhv5NYKTRErPb2Z1ezbmZ0dBbSqqkKzarvB/6wmXmmMtlAbu93uQZRlibaG0s7G8zwsl0tYliXZtm0IIZCm6S237SRJEEXRccHVysRkMsFsNsN0OsXs+hq6rsMwDPwm1vU5FovFfo+4vni9To4LhkFAQQw2BVqMwaEMa99iJphpSr5xGJhkh8RipJzfJyhd3Hgx9JUPy+NwAgHmJuR7WDmx9G3CT4Njbu5jTJfDj/jxDN36Dals3w/pdo4gjMAsG67rI4rXEr+WAfS5gSCIpL9O7im5ceI4kmW6rosoDOnhObJM7JsgMok8zyGyDdk5eJoh4UJ2+lbw8B9tNhsURYHtdnvw0wpCfoAMKAWdLRuRuwxrodFoBFVV0ev1oGmatNXuObq9AU4vApxd5nh3KfD2i8CbzwKvLwTeX+XIilbJTbohldjpdKAoyt949ATKMxPKS5qEF3ToOfEJ4Snw+BVAPZNNLavWpNRljsdj9Pt9DIfDOwz6GHz8BO2rgHoFdL9XxBU0wvm3Ch9+VMjbGR7O8j8m9oFZ3vMfaTG/sBm9P0EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an API key destination selector",
            "title": "Example of an API key destination selector",
            "src": "/static/26eff50f4435f9134ed1de64674cbce4/fb070/7.png",
            "srcSet": ["/static/26eff50f4435f9134ed1de64674cbce4/d6747/7.png 288w", "/static/26eff50f4435f9134ed1de64674cbce4/09548/7.png 576w", "/static/26eff50f4435f9134ed1de64674cbce4/fb070/7.png 1152w", "/static/26eff50f4435f9134ed1de64674cbce4/c3e47/7.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of an API key destination selector</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "restricted-access-keys"
    }}>{`Restricted access keys`}</h2>
    <p>{`A user may want to limit the access an application or service is granted. Restricted access allows a user to assign specific access control and permissions before the API key is generated.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "781px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "148.39948783610754%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAACXBIWXMAAAsSAAALEgHS3X78AAADo0lEQVRIx5VWXUiTURjuuqvqJroztahWoCRos6suvfRCKAlRIW8qcC2DbYTYbfvVm6AuZIYiggQFXQSLMgzx35yh1qRwm9u+fd/mvu3b3/d03rOWQ2ydDrx859t2nvOc93ne9+yY2+1GZbhcLng8HoyMjPAYHh7m70dF+feV648dBnQ4HJiZmcH29jbW19cRCAQQDocRDAZ57O7uIhqNYmNjg28mBLiysgJJkhAKhRCJRPhclmUe8XgcxWIRiUSCn+CfgHa7HWtra9jf3+eLUqkUn+fzeRQKBf6kEYvFxAHpOKqqckbpdBqZTIa/U9AGmqZx9sKAq6urfBExJBACVVUGrGUZyyJnGBVlSDlcXl7m7IjNwdArAkjIkjjDxcVFniMSg1gqSgJyqghZBeIpHUkNCOxKTOUqgPQFBe3a3t4Og8HA4jIMly7g4pWrqOnbxGkTcPp+HqfuAZfMMdhdI2wtrasCSPOhoSFYLBbYbDZYrVZYbY8x4HgNs/s9HjjfweT04ZHzDZwuz98BK49MhiZly3bJ5bLIqhJ0Lc6yl2aRhxz5watFSJSlpSVuaKoQEoZUTmc0aFnmxZLIiERjYoBl25BdqCIOD10vqUzlJwzo9/s5IClMfqTjc5bcjypLQY4bWxiQcki5oxyWK4SAs9ksfxLzfwJW2qajowMNDQ1oampCY2MjjEYjpqenMT8/j9nZWczNzcHn81VvX2VA2pXs0t/fD7PZDJPJhIGBAXi9XkxNTWFiYgKTk5MYGxsT64eV3UZRFJ43UppEoMqhOR19Z2dH3DYLCwt/mioBkCgUJEZ5ELiwKF8YQ0r+UUPXi/9nG4fDjrlFP3ZCSdYAFIQkDcFYBkEp82ceSRTw9XuINQcRhk+fYnPrG2eRy+sHDYuYFQvsUerY0ciemG3o4unsvIWWlma0trbCeK0ZLddvoPHhT1weBM5bgFoW1x7HqzeHStuQVbq6utDT24ue7m50995Bp+0Vbg59xM1BHzoGP6DnydvfQALdhmxDdiHblG87LRkFsnKp4xSSkMIBsWuUAOkaJbC9vT2uJnWemKwgqWpIplht53QEw9H/u/W4KMx3/PrMF5FWWD2zZgF9v/RdShDQ6XRidHQU4+PjrNzGWIl54X05gefvVDz7ALz4yOIT2LsCt0fwTmlra0NNTQ3q68+hru4sas8ZcOb2Z5zsU3CiJ4Tj3TFcuLsFu3O4uijl3Q7vyj9zk0V+B/nPffSfpV9D7Rr47NPFdQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a restricted API key generation",
            "title": "Example of a restricted API key generation",
            "src": "/static/164a0d8d34ebdc6b26523db36d3a57ae/6ae69/8.png",
            "srcSet": ["/static/164a0d8d34ebdc6b26523db36d3a57ae/d6747/8.png 288w", "/static/164a0d8d34ebdc6b26523db36d3a57ae/09548/8.png 576w", "/static/164a0d8d34ebdc6b26523db36d3a57ae/6ae69/8.png 781w"],
            "sizes": "(max-width: 781px) 100vw, 781px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      